<template>
  <div>
    <div v-if="payload?.title" class="gr--header">
      <div class="gr--header--subtitle" v-html="payload?.subTitle" v-if="payload?.subTitle"></div>
      <div class="gr--header--title">
        {{ payload?.title }}
      </div>
    </div>

    <div :class="payload.customClass">
      <template v-for="i in payload.content">
        <div>
          <NuxtLinkLocale :to="i.ctaLink ? checkAndAddSlash(i.ctaLink) : '#'" :class="[i.customClass, 'block']">
          <div class="grid-content-wrapper">
            <div class="z-30 flex inset-0">
              <div :class="[
                  'grid-cta',
                  horizontalClass(i.config.titleHPlacement),
                  verticalClass(i.config.titleVPlacement),
                  { [backdropClass]: payload.config?.text_backdrop_enable }
                ]"
                :data-h-placement="i.config.titleHPlacement"
                :data-v-placement="i.config.titleVPlacement"
                :data-backdrop="payload.config?.text_backdrop_enable"
                v-if="!i.config?.titleIsOutbox && (i.title || i.subTitle)"
              >
                <span :class="titleClass">{{ i.title }}</span>
                <span :class="subTitleClass">{{ i.subTitle }}</span>
              </div>
            </div>
            <NuxtImg :class="[i.config?.imgClass, 'z-0']" :src="getImageForDevice(i)" :alt="i.title" placeholder
              :quality="65" sizes="xs:300px md:512px lg:768px" fit="cover" format="webp" loading="lazy"
              class="min-h-full min-w-full object-cover" />
          </div>
          
        </NuxtLinkLocale>
        <div :class="[
                  'grid-cta !text-primary',
                  horizontalClass(i.config.titleHPlacement),
                  verticalClass(i.config.titleVPlacement),
                  { [backdropClass]: payload.config?.text_backdrop_enable }
                ]"
                :data-h-placement="i.config.titleHPlacement"
                :data-v-placement="i.config.titleVPlacement"
                :data-backdrop="payload.config?.text_backdrop_enable"
                v-if="i.config?.titleIsOutbox"
              >
          <span :class="titleClass">{{ i.title }}</span>
          <span :class="subTitleClass">{{ i.subTitle }}</span>
        </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps(['payload'])
import { horizontalClass, verticalClass } from "~/composables/useHelper";

const backdropClass = computed(() => props.payload.config?.text_backdrop_class ? props.payload.config?.text_backdrop_class : 'bg-gradient-to-t from-black/40 via-transparent to-transparent')
const titleClass = computed(() => props.payload.config?.title_class ? props.payload.config?.title_class : 'text-4xl')
const subTitleClass = computed(() => props.payload.config?.sub_title_class ? props.payload.config?.sub_title_class : 'text-xl')
</script>

<style lang="scss">
.grid-content-wrapper {
  @apply relative overflow-hidden h-full w-full;
}

.grid-cta {
  @apply w-full z-30 px-6 py-6 text-white flex space-y-2 tracking-wider;
}
</style>